import PageNotFound from "@/components/PageNotFound";
import Footer from "@/components/layout/Footer";
import Header from "@/components/layout/Header";
import Menu from "@/components/layout/Menu";
import ListingNoAvailable from "@/components/listing/ListingNoAvaliable";
import { serverApi } from "@/libs/serverApi";
import { GetServerSideProps, NextPage } from "next";
import Error from "next/error";

interface ErrorPageProps {
  statusCode: number;
}
const CustomError: NextPage<ErrorPageProps> = ({ statusCode }) => {
  if (statusCode === 404) {
    return (
      <div className="w-full">
        <Header />
        <Menu />
        <PageNotFound />
        <Footer />
      </div>
    );
  } else if (statusCode === 410) {
    return (
      <div className="w-full">
        <Header />
        <Menu />
        <ListingNoAvailable similarData={[]} slug="" />
        <Footer />
      </div>
    );
  }
  return <Error statusCode={statusCode} />;
};

export const getServerSideProps: GetServerSideProps = async ({ res, req }) => {
  const slugs = req.url?.split("/").filter(Boolean) ?? [];
  let statusCode = res.statusCode;
  if (req.url?.startsWith("/listing") && slugs.length > 1) {
    const { getDataBySlug } = serverApi();
    const response = await getDataBySlug(slugs[1]);
    const listingData = response?.data;
    if (listingData) {
      statusCode = 410;
    }
  }
  res.statusCode = statusCode;

  return {
    props: {
      statusCode,
    },
  };
};

export default CustomError;
